import React from "react";

function HelpPage()
{
    return (
        <>
            <div className="contentsAreaHeader">
                <div className="areaTitle">Support</div>
                <div className="areaExplainer">epIMS help information</div>
            </div>
            <div className="contents">
                <p>We have created a series of videos to help you use the epIMS platform.</p>
                <p><strong>Welcome to epIMS</strong></p>
                <iframe width="640" height="360" src="https://www.youtube-nocookie.com/embed/3U-5p7-K6vU" title="YouTube video player" frameborder="0" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <p className="mt-4"><strong>Types of epIMS report</strong></p>
                <p>We provide two types of report. An introductory Preliminary Report, and an advanced Premium Report. You can generate Preliminary Reports for free from your customers. The Preliminary Report explains the benefits of the Premium Report and if one of your customers chooses to upgrade to a Premium Report we will pay you a referral fee. Alternatively, you can buy the Premium Report from us at a reduced rate and sell it to your customer for a price of your choice.</p>
                <p className="mt-4"><strong>Creating a Preliminary Report</strong></p>
                <iframe width="640" height="360" src="https://www.youtube-nocookie.com/embed/wzitYqQTpRU" title="YouTube video player" frameborder="0" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <p className="mt-4"><strong>Creating your Premium Report</strong></p>
                <iframe width="640" height="360" src="https://www.youtube-nocookie.com/embed/Rmi514RxC-8" title="YouTube video player" frameborder="0" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <p className="mt-4"><strong>Customising report branding</strong></p>
                <iframe width="640" height="360" src="https://www.youtube-nocookie.com/embed/I47fStMA3DM" title="YouTube video player" frameborder="0" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </>
    )
}

export default HelpPage;