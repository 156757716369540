import { useLocation } from 'react-router-dom';

function RegisterCheckPage() {
  const { state } = useLocation()

  return (
    <div className="d-flex justify-content-center">
      <div className="d-flex flex-column">
        <div className="card" style={{ width: '45rem' }}>
          <div className="card-header"><strong>Thank you!</strong></div>
          <div className="card-body">
            <div>Thank you for registering with epIMS!</div>
            <div>We need to verify you own the email address <strong>{state.email}</strong>.</div>
            <div>We have sent you an email with a confirmation link, please check your email and click the link.</div>
            <div>If you can't find the email please check your spam folder.</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterCheckPage;
