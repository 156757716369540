import { useEffect } from 'react';
import React, { useState } from 'react';
import ReorderableMeasuresList from './ReorderableMeasuresList';

function ReorderablePlan(props) {
    
    const [measures, setMeasuresState] = useState({});
    const [measuresOrder, setMeasuresOrderState] = useState([]);
    const [planActive, setPlanActive] = useState(props.planId === 'custom' ? false : true)

    const setMeasures = (_measures) => {
        setMeasuresState(_measures);
        props.updateMasterData(_measures, 'measures');
    }

    const setMeasuresOrder = (_measuresOrder) => {
        setMeasuresOrderState(_measuresOrder);
        props.updateMasterData(_measuresOrder, 'measuresOrder');
    }

    useEffect(() => {
        props.updateMasterData(planActive, 'planActive')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planActive])

    useEffect(() => {
        // if there is no ordering currently set, then build it...
        var ordering = [];
        var _measures = {};
        if (measuresOrder.length === 0) {
            // build the ordering            
            props.plan['data']['measures'].forEach(measure => {
                ordering.push(measure.code);
                measure['eirTotal'] = 0; // these get set to correct values inside recalculateTotals() which gets called because of useEffect hook on measuresOrder
                measure['epcTotal'] = 0; //  "   "   "   "
                _measures[measure.code] = measure;
            });
            _measures = applyTotalsToMeasures(ordering, _measures);
            setMeasures(_measures);
            setMeasuresOrder(ordering); // not sure if it will make a different but this one is last as it triggers an effect which needs everything else in place
        } else {
            // identify any measures in the plan that are not in the current ordering, and add them to the end of the ordering
            ordering = measuresOrder;
            _measures = {...measures}
            props.plan['data']['measures'].forEach(measure => {
                if (!ordering.includes(measure.code)) {
                    ordering.push(measure.code)
                    _measures[measure.code] = measure
                }
            })
            // identity any measures in the ordering that are no longer in the plan (and remove them from the ordering and local measures list)
            // console.log(ordering)
            ordering.forEach(o => {
                // console.log(props.plan['data']['measures'].filter(m => m.code === o))
                if ((props.plan['data']['measures'].filter(m => m.code === o)).length === 0) {
                    // console.log("Looks like we should remove something, code " + o)
                    ordering = ordering.filter(o2 => o !== o2)
                    delete _measures[o]
                }
            })
            _measures = applyTotalsToMeasures(ordering, _measures);
            setMeasures(_measures)
            setMeasuresOrder(ordering)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.plan])

    const applyTotalsToMeasures = (_order, _measures) => {
        // this will recalculate running totals for both EPC and EIR, even though in this case only one is being used, the other is not referenced.
        // it will also apply running totals for costs
        var newEpcTotal = props.epcStart
        var newEirTotal = props.eirStart
        var costTotal = 0;
            
        _order.forEach(code => {
            newEpcTotal += _measures[code].epcIncrease;
            newEirTotal += _measures[code].eirIncrease;
            costTotal += _measures[code].implementationCost;
            _measures = {
                ..._measures,
                [code]: {..._measures[code], eirTotal: newEirTotal, epcTotal: newEpcTotal, costTotal}
            }
        });
    
        return _measures;
    }

    var extraText = '';
    if (props.planId === "custom") {
        extraText = '';
    } else if (props.plan.collect === 'EIR') {
        extraText = 'Update: EIR, starts at ' + props.eirStart;
    } else if (props.plan.collect === 'EPC') {
        extraText = 'Update: EPC, starts at ' + props.epcStart;
    }

    const planId = props.planId

    return (
        <div className="reorderablePlanContainer">
            <div style={{zIndex: 100, display: planActive ? "none" : "initial"}} className="disabled">
            </div>
            <input style={{zIndex: 1000, position: "absolute", top: "10px", right: "10px", width: "30px", height: "30px"}} type="checkbox" checked={planActive} onChange={(e) => setPlanActive(e.target.checked)}/>
            <div className="pageSubSection">{props.plan.name}</div>            
            <div>{extraText}</div>
            <ReorderableMeasuresList {...{measures, setMeasures, measuresOrder, setMeasuresOrder, applyTotalsToMeasures, planId}} epcStart={props.epcStart} eirStart={props.eirStart} plan={props.plan} allowPointsChange={props.planId === "custom" ? false : true} />
        </div>
    );
}

export default ReorderablePlan;