import Account from './Account'
import Settings from './Settings'
import HelpPage from './HelpPage'
import EarningsPage from './EarningsPage'
import UploadXmlPage from './UploadXmlPage'
import { useState, useEffect } from 'react'
import NavOption from './components/NavOption'
import { subscribe, unsubscribe } from './events'
import ReportsList from './components/ReportsList'
import EpimsLogoOnly from './components/EpimsLogoOnly'
import PendingReports from './components/PendingReports'
import FriendlyGreeting from './components/FriendlyGreeting'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

function HomePage(props) {

  // on component mount, fetch the latest user settings (this is mostly to get the email template text) and then subscribe to messages indicating that the settings have changed
  // on one of those messages, fetch again
  // on unmount, unsubscribe
  useEffect(() => {
    props.fetchUserSettings();
    const handleSettingsUpdateMessage = (body) => {
      props.fetchUserSettings();
    }
    subscribe('settingsUpdate', handleSettingsUpdateMessage);
    return () => {
      unsubscribe('settingsUpdate', handleSettingsUpdateMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [messagesLoading, setMessagesLoading] = useState(true)
  const [newTerms, setNewTerms] = useState([])
  const [newTermsTime, setNewTermsTime] = useState(0)
  const [newMessage, setNewMessage] = useState({})
  const [showMessageType, setShowMessageType] = useState('')
  const [messagesCleared, setMessagesCleared] = useState(false)

  const checkMessages = () => {
    setMessagesLoading(true)
    setMessagesCleared(false)
    const url = process.env.REACT_APP_API_END_POINT + 'checkmessages';
    fetch(url, {
      headers: {
        'Accept': 'application/json',
      },
      method: "GET",
      credentials: "include",
    })
    .then((resp) => resp.json())
    .then((data) => {
      if (data['success']) {
        setMessagesLoading(false)
        if (data['type'] === 'NONE') {
            setMessagesCleared(true)
        } else if (data['type'] === 'TERMS') {
            setNewTerms(data['terms'])
            setNewTermsTime(data['updated_at'])
            setShowMessageType('terms')
        } else if (data['type'] === 'MESSAGE') {
            setNewMessage(data['message'])
            setShowMessageType('message')
        }
      }
    });
  }

  const acceptTerms = () => {
    setMessagesLoading(true)
    const url = process.env.REACT_APP_API_END_POINT + 'checkmessages';
    fetch(url, {
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        type: 'TERMS',
        acceptTerms: true,
        acceptedTo: newTermsTime,
      })
    })
    .then((resp) => resp.json())
    .then((data) => {
      if (data['success']) {
        checkMessages()
      }
    });
  }

  const answerMessage = (e, response) => {
    setMessagesLoading(true)
    const url = process.env.REACT_APP_API_END_POINT + 'checkmessages';
    fetch(url, {
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        type: 'MESSAGE',
        id: newMessage.id,
        response
      })
    })
    .then((resp) => resp.json())
    .then((data) => {
      if (data['success']) {
        checkMessages()
      }
    });
  }

  // on component mount, check if there are any messages for us
  useEffect(() => {
    checkMessages()
  }, [])

  const showMessage = () => {
    return (
      <>
        <div className="contentsAreaHeader">
          <div className="areaTitle">A message from epIMS</div>
          <div className="areaExplainer">Please read the message before continuing</div>
        </div>
        <div className="contents">
          <div style={{display: "flex", flexDirection: "column", width: "650px", height: "90%", marginInline: "auto", marginBlock: "auto", borderRadius: "30px", border: "1px solid black", padding: "6px"}}>
              <div style={{marginInline: "auto"}}>
                  <EpimsLogoOnly xsmall />
              </div>
              {showMessageType === 'terms' && <>
                  <p style={{fontWeight: "bold", fontSize: "30px"}}>We have updated our terms and conditions since your last visit</p>
                  <p style={{fontWeight: "bold", fontSize: "20px"}}>Please accept the updated terms below</p>
                  {newTerms.map(term => {return <>
                      <p style={{fontWeight: "bold"}}>{term.title}</p>
                      <p dangerouslySetInnerHTML={{ __html: term.message_html}} />
                  </>})}
                  <div style={{marginInline: "auto"}}>
                      <button id="acceptTermsButton" onClick={acceptTerms} className="btn btn-primary">Accept all updated terms</button>&nbsp;<button id="declineTermsButton" onClick={props.doLogout} className="btn btn-secondary">Decline updates and log out</button>
                  </div>
              </>}
              {showMessageType === 'message' && <>
                  <p style={{fontWeight: "bold"}}>{newMessage.title}</p>
                  <p dangerouslySetInnerHTML={{ __html: newMessage.message_html}} />
                  <div style={{marginInline: "auto"}}>
                    {newMessage.options.map((option, idx) => {
                      if (option.type === 'button') {
                        return <button style={{marginInline: "6px"}} onClick={(e) => answerMessage(e, option.value)} className={idx === 0 ? "btn btn-primary" : "btn btn-secondary"}>{option.value}</button>
                      }
                      return <></>
                    })}
                  </div>
              </>}
          </div>
        </div>
      </>
    )
  }

  const mainComponent = () => {
    if (messagesLoading) return <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column"}}><FontAwesomeIcon className="spinner-slow" icon={solid("spinner")} style={{marginInline: "auto", marginBlock: "auto", color: "#D0D0D0", height: "150px", width: "150px" }} /></div>
    if (!messagesCleared) return showMessage()
    if (props.activeTab === 'myaccount') return <Account />
    if (props.activeTab === 'settings') return <Settings />
    if (props.activeTab === 'main') return <ReportsList key="regular" userSettings={props.userSettings} mode="preliminary" deleted={false} userRole={props.userRole} />
    if (props.activeTab === 'deleted') return <ReportsList key="deleted" userSettings={props.userSettings} mode="preliminary" deleted={true} userRole={props.userRole} />
    if (props.activeTab === 'new') return <UploadXmlPage userAccreditationScheme={props.userAccreditationScheme} />
    if (props.activeTab === 'pending') return <PendingReports userRole={props.userRole} userSettings={props.userSettings} />
    if (props.activeTab === 'premium') return <ReportsList key="premium" userSettings={props.userSettings} mode="premium" deleted={false} userRole={props.userRole} />
    if (props.activeTab === 'earnings') return <EarningsPage />
    if (props.activeTab === 'help') return <HelpPage />
  }

  const showPremiumSection = (props.pendingReportsCount !== 0) || props.showPremiumReportsTab

  return (
    <div className="loggedInContainer">
      <div className="loggedInHeader"></div>
      <div className="loggedInMainArea">
        <div className="navMenu">
          <EpimsLogoOnly xxxsmall={true} />
          <FriendlyGreeting userRealName={props.userRealName} />
          <div className="navSection">
            <div className="navSectionName">Reports</div>
            <NavOption activeTab={props.activeTab} url='/uploadXml' name='new' displayName='Create Report' />
            <NavOption activeTab={props.activeTab} url='/home' name='main' displayName='Report List' />
            <NavOption activeTab={props.activeTab} url='/deleted' name='deleted' displayName='Deleted Reports' />
            <NavOption activeTab={props.activeTab} url='/earnings' name='earnings' displayName='My Earnings' />
          </div>
          {showPremiumSection && <div className="navSection">
            <div className="navSectionName">Premium Reports</div>
            {(props.pendingReportsCount !== 0) && <NavOption notifications={props.pendingReportsCount} activeTab={props.activeTab} url='/pending' name='pending' displayName='Pending' /> }
            {props.showPremiumReportsTab && <NavOption activeTab={props.activeTab} url='/premium' name='premium' displayName='Premium Reports' /> }
          </div> }
          <div className="navSection">
            <div className="navSectionName">Settings</div>
            <NavOption activeTab={props.activeTab} url='/settings' name='settings' displayName='Branding' />
            <NavOption activeTab={props.activeTab} url='/account' name='myaccount' displayName='My Account' />
          </div>
          <div className="navSection">
            <div className="navSectionName">Support</div>
            <NavOption activeTab={props.activeTab} url='/help' name='help' displayName='Help' />
          </div>
          <div style={{textAlign: "center", marginRight: "15px", marginTop: "24px"}}><button id="logOutButton" className="btn btn-primary" onClick={props.doLogout}>Log out</button></div>
          <div className="termsLinks">
              <p><a style={{textDecoration: "none"}} href={"https://" + process.env.REACT_APP_WWW_BASE_DOMAIN + "/terms/EPA%20Privacy%20Policy.pdf"} target="_blank" rel="noreferrer">Privacy Policy</a></p>
              <p><a href={"https://" + process.env.REACT_APP_WWW_BASE_DOMAIN + "/terms/EPA%20Terms.pdf"} target="_blank" rel="noreferrer">Terms and Conditions</a></p>
          </div>
        </div>
        <div className="contentArea">
          { mainComponent() }
        </div>
      </div>
    </div>
  );

}

export default HomePage;
