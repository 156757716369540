import EpimsLogo from "./components/EpimsLogo";

function SharedReportNotFound() {

  const currentOrigin = window.location.origin;
  const wwwOrigin = currentOrigin.replace('app', 'www')

  return (
    <>
      <div className="topLeftLogo"><EpimsLogo /></div>
      <div className="sharedLandingColumn">
        <p className="sharedLandingTitle">Ooops!</p>
        <p className="sharedLandingSubtitle">We&apos;re sorry but it looks like this link is invalid or has expired. Please contact the estate agent or surveyor who gave you the link or visit <a href={wwwOrigin + "/my-free-report/"}>our questionnaire</a> to get an updated report immediately.</p>
      </div>

    </>
  );
}

export default SharedReportNotFound;