import React, { useEffect } from "react";
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { subscribe, unsubscribe } from './events';

function EarningsPage(props)
{
    const [lifetimeEarnings, setLifetimeEarnings] = useState(0)
    const [lifetimeReports, setLifetimeReports] = useState(0)
    const [monthlyBreakdown, setMonthlyBreakdown] = useState([])
    const [currentPremiumPricePence, setCurrentPremiumPricePence] = useState(0)
    const [currentPremiumReferral, setCurrentPremiumReferralPence] = useState(0)
    const [loading, setLoading] = useState(true);

    const fetchCommissionData = () => {
        const url = process.env.REACT_APP_API_END_POINT + 'agentearnings/'
        fetch(url, {
            headers: {
                'Accept': 'application/json',
            },
            method: "GET",
            credentials: "include"
        })
        .then((resp) => resp.json())
        .then((data) => {
            if (data['success']) {
                setLifetimeEarnings(data['lifetime_earnings_pence'])
                setLifetimeReports(data['lifetime_reports'])
                setMonthlyBreakdown(data['monthly_breakdown'])
                setCurrentPremiumPricePence(data['current_premium_price_pence'])
                setCurrentPremiumReferralPence(data['current_upgrade_referral_pence'])
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        fetchCommissionData();
        const handleUpdateMessage = (body) => {
            fetchCommissionData();
        }
        subscribe('newCommissionPayment', handleUpdateMessage)
        return () => {
            unsubscribe('newCommissionPayment', handleUpdateMessage)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    

    const getMainContent = () => {
        if (loading) return <FontAwesomeIcon className="spinner" icon={solid("spinner")} style={{color: "#000000", height: "50px", width: "50px" }} />
        return (
            <>
                <p><strong>Our offering</strong></p>
                <p>By using the epIMS Platform you can not only increase your earnings but also give your clients a professional, well rounded approach to tackling inefficient properties. There are two routes to increasing revenue with epIMS.</p>
                <p>1. You produce a <strong>FREE Preliminary report</strong>, we call it an EPC&#43;, this can be done with EVERY EPC you complete and provides a client more information to go against the EPC. The more Preliminary reports you distribute, the better chances someone will upgrade to a Premium report and you recieve &pound;{(currentPremiumReferral / 100).toFixed(2).replace(/\.00$/, '')}. You will receive an email that your Preliminary Report has been upgraded, this can also be tracked in the &lsquo;My Earnings&rsquo; section of the Platform</p>
                <p>2. You purchase a <strong>Premium Report</strong> for £{(currentPremiumPricePence / 100).toFixed(2).replace(/\.00$/, '')}, this report is a comprehensive and detailed report that is designed to get the homeowner informed and ready to undertake work&#47;request quotes. You are able to purchase this directly from epIMS and re-sell at a price point of your choosing,</p>
                <div className="earningsReport">
                    <p className="grandTotal">Total Preliminary upgrade earnings: &pound;{(lifetimeEarnings / 100).toFixed(2)} - from {lifetimeReports} {lifetimeReports === 1 ? 'report' : 'reports'}</p>
                    <div className="mainGrid">
                        {
                            monthlyBreakdown.map((mb) => {
                                return (
                                    <>
                                        <div className="monthlyTotal">{mb.month} (Total: &pound;{(mb.total_pence / 100).toFixed(2)}, {mb.payment_status})</div>
                                        <div className="amount heading">Amount</div>
                                        <div className="assessor_ref heading">Assessor Ref</div>
                                        <div className="entry_time heading">Date &amp; Time</div>
                                        <div className="address heading">Address</div>
                                        {
                                            mb.entries.map((entry) => {
                                                return (
                                                    <>
                                                        <div className="amount">&pound;{(entry.amount_pence / 100).toFixed(2)}</div>
                                                        <div className="assessor_ref">{entry.assessor_ref}</div>
                                                        <div className="entry_time">{entry.time}</div>
                                                        <div className="address">{entry.address}</div>
                                                    </>
                                                )
                                            })
                                        }
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="contentsAreaHeader">
                <div className="areaTitle">My Earnings</div>
                <div className="areaExplainer">This will list any earnings you have from Premium upgrades of Preliminary reports you generated</div>
            </div>
            <div className="contents">
                {getMainContent()}
            </div>
        </>
    )
}

export default EarningsPage;